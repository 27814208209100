<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div style="padding: 20px 0px 0px 20px">
            <el-button type="primary" @click="show(0)">列表</el-button>
            <el-button type="primary" @click="show(1)">拓补图</el-button>
        </div>
        <hwg-table v-if="this.tableShow" v-bind:deviceName="this.deviceName" :device-id="this.deviceId"></hwg-table>
        <hwg-topology v-if="this.topologyShow" v-bind:hwgNumber="this.hwgNumber"></hwg-topology>
    </div>
</template>
<script>
import HwgTable from './hwg';
import HwgTopology from './hwgTopology';
export default {
    name: 'HwgYx3',
    components: {
        HwgTable,
        HwgTopology
    },
    data() {
        return {
            tableShow: true,
            topologyShow: false,
            hwgNumber: 'yxx3',
            deviceName: '10kV园小线3号户外开关站',
            deviceId: 19,
        };
    },
    methods: {
        show(type) {
            if (type) {
                this.tableShow = false;
                this.topologyShow = true;
            } else {
                this.tableShow = true;
                this.topologyShow = false;
            }
        },
    },
};
</script>
